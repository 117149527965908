import { request } from '../utilities/httpRequestHandler';
import config from '../config/config';


const fetchTotalMantras = async (practiceType) => {
  try {
    const url = practiceType ? `${config.API_BASE_URL}/api/total?practiceType=${encodeURIComponent(practiceType)}` : `${config.API_BASE_URL}/api/total`;
    const response = await request('GET', url);
    return response;
  } catch (error) {
    console.error("Error fetching total mantras:", error);
    throw error;
  }
};

const addMantraCount = async (practiceType, count) => {
    try {
      await request('POST', `${config.API_BASE_URL}/api/insert`, {
        practiceType,
        count,
      });
    } catch (error) {
      console.error("Error adding mantra count:", error);
      throw error;
    }
};

const fetchLastMantraCount = async (practiceType) => {
  try {
    const response = await request('GET', `${config.API_BASE_URL}/api/last?practiceType=${encodeURIComponent(practiceType)}`);
    return response;
  } catch (error) {
    console.error("Error fetching last mantra count:", error);
    throw error;
  }
};


const undoLastMantraCount = async (practiceType) => {
  try {
    await request('DELETE', `${config.API_BASE_URL}/api/undo?practiceType=${encodeURIComponent(practiceType)}`);
  } catch (error) {
    console.error("Error undoing last mantra count:", error);
    throw error;
  }
};

export { fetchTotalMantras, addMantraCount, undoLastMantraCount, fetchLastMantraCount };
