import React, {  } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CountUp from 'react-countup'; // For animated numbers
import { useLocation } from 'react-router-dom';

function Footer({ overallTotal }) {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  const getNgondroTranslationKey = (total) => {
    const lang = i18n.language;
    if (lang === 'cs') {
      if (total === 1) return 'ngondro_1';
      if (total >= 2 && total <= 4) return 'ngondro_2';
      return 'ngondro';
    } else {
      return total === 1 ? 'ngöndro' : 'ngöndros';
    }
  };

  const ngondroKey = getNgondroTranslationKey(overallTotal);


  // Function to dynamically create mailto link
  const createMailtoLink = () => {
    const user = "jozef.huscavaZA";
    const domain = "Vgmail.com";
    return `mailto:${user}${domain}`;
  };
  
  return (
    <Box component="footer" sx={{ backgroundColor: 'grey.200', py: 3, mt: 4 }}>
      <Container maxWidth="sm">
        {location.pathname !== '/practice/karmapa-chenno' && (
        <Typography variant="h6" display="block" align="center" sx={{ color: '#c90033', fontWeight: 'bold' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1, flexWrap: 'wrap', }}>
            {t('overallTotalFooter', 'Všichni společne jsme udělali')}
            <CountUp separator='.' end={overallTotal} duration={2.75} />
          {`${t(ngondroKey)}`}
          </Box>
        </Typography>
        )}
        <Typography variant="body2" color="textSecondary" align="center" sx={{ mt: 3, }}>
        {t('footerText', 'V případě problémů kontaktuje:')} <a href="#" onClick={() => window.location.href = createMailtoLink()}>{t('footerEmail_alt', 'jozef.huscavaZAVgmail.com')} </a>
        </Typography>
        <Typography variant="caption" display="block" align="center" sx={{ mt: 1 }}>
          {t('footerCopyright', 'Copyright © 2024')}
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
