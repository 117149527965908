import React, { useState, useEffect, useCallback } from 'react';
import { Container, Typography, Box, TextField, Button, List, ListItem, Paper } from '@mui/material';
import { Header } from '../components/Header';
import Footer from '../components/Footer';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import * as service from '../services/service';

function MantraCountPage({ titleKey, titleDefault }) {
  const [mantraData, setMantraData] = useState({
    totalMantras: 0,
    overallTotal: 0,
    lastRecords: [],
    lastInsertedNumber: null,
    canUndo: false,
  });
  const [mantraCountText, setMantraCountText] = useState('');
  const [errorText, setErrorText] = useState('');
  const { t } = useTranslation();
  const location = useLocation();

  const getPracticeType = useCallback(() => {
    if (location.pathname.includes('/practice/prostrations')) {
      return 'prostrations';
    } else if (location.pathname.includes('/practice/diamond-mind')) {
      return 'diamond-mind';
    } else if (location.pathname.includes('/practice/mandala-offerings')) {
      return 'mandala-offerings';
    } else if (location.pathname.includes('/practice/guru-yoga')) {
      return 'guru-yoga';
    } else if (location.pathname.includes('/practice/karmapa-chenno')) {
      return 'karmapa-chenno';
    }
    return 'unknown';
  }, [location.pathname]);

  const updateMantraCounts = useCallback(async () => {
    const practiceType = getPracticeType();
    if (practiceType !== 'unknown') {
      const totalResponse = await service.fetchTotalMantras(practiceType);
      const lastCountData = await service.fetchLastMantraCount(practiceType);
      setMantraData({
        totalMantras: totalResponse.totalPerPractice,
        overallTotal: totalResponse.nogondros,
        lastRecords: totalResponse.lastRecords,
        lastInsertedNumber: lastCountData.lastNumber,
        canUndo: lastCountData.canUndo,
      });
    }
  }, [getPracticeType]);

  useEffect(() => {
    updateMantraCounts();
  }, [updateMantraCounts, location.pathname]);

  const handleAddMantraCount = async () => {
    const practiceType = getPracticeType();
    const parsedCount = parseInt(mantraCountText, 10);

    if (!mantraCountText) {
      setErrorText(t('emptyFieldError'));
    } else if (isNaN(parsedCount) || parsedCount <= 0) {
      setErrorText(t('nonZeroPositiveIntegerError'));
    } else if (practiceType !== 'unknown') {
      try {
        await service.addMantraCount(practiceType, parsedCount);
        setMantraCountText('');
        updateMantraCounts();
        setErrorText('');
      } catch (error) {
        console.error("Error:", error);
        setErrorText(t('genericErrorMessage'));
      }
    } else {
      console.error("Invalid input: Mantra count must be a non-zero, non-negative whole number.");
    }
  };

  const handleUndoLastMantraCount = async () => {
    try {
      const practiceType = getPracticeType();
      if (practiceType !== 'unknown' && mantraData.canUndo) {
        await service.undoLastMantraCount(practiceType);
        updateMantraCounts();
      } else {
        console.error("Undo action not allowed or practice type is unknown.");
      }
    } catch (error) {
      console.error("Error undoing last mantra count:", error);
    }
  };

  // Using Intl.NumberFormat to format numbers with spaces
  const formatNumber = (number) => {
    return new Intl.NumberFormat('sk-SK').format(number);
  };
  
  // Determine the term based on the URL
  let termKey = 'mantras'; // Default value
  if (location.pathname.includes('/practice/prostrations')) {
    termKey = 'numberOfProstrations';
  } else if (location.pathname.includes('/practice/mandala-offerings')) {
    termKey = 'numberOfOfferings';
  }
  
  return (
    <>
      <Header>
        <Typography variant="h4" component="div" gutterBottom>
          {t(titleKey, titleDefault)}
        </Typography>
      </Header>
      <Container maxWidth="sm" sx={{ my: 4, backgroundColor: 'grey.100', p: 2, borderRadius: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <TextField
            label={t('insertMantraCount', 'Vložte počet manter')}
            variant="outlined"
            value={mantraCountText}
            onChange={(e) => setMantraCountText(e.target.value)}
            type="number"
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ flex: 1, mr: 1 }}
          />
          {errorText && (
            <Box sx={{ maxWidth: '30%', mx: 'auto', textAlign: 'center' }}>
              <Typography color="error">
                {errorText}
              </Typography>
            </Box>
          )}
          {mantraData.canUndo && (
            <Button variant="contained" color="inherit" onClick={handleUndoLastMantraCount}>
              {t('undo', 'Zpět')}
            </Button>
          )}
          <Button variant="contained" color="primary" onClick={handleAddMantraCount}>
            {t('submit', 'Odešli')}
          </Button>
        </Box>
        <Paper sx={{ p: 2, my: 2, bgcolor: 'primary.light', textAlign: 'center' }}>
          <Typography variant="h6">
            {t('totalCollected', 'Dosud jsme shromáždili:')} {formatNumber(mantraData.totalMantras)} {t(termKey)}
          </Typography>
        </Paper>
      </Container>
      <Container maxWidth="sm" sx={{ my: 4 }}>
        <Typography variant="h6" align="center" gutterBottom>
          {t('recentlyAdded', 'Nedávno vložené počty')} {t(getPracticeType(), '')}
        </Typography>
        <List sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {mantraData.lastRecords.map((record, index) => (
            <ListItem key={index} sx={{ justifyContent: 'center' }}>
              <Typography component="span" variant="body1" sx={{ fontWeight: 'bold' }}>
                {record.count}
              </Typography>
              <Box component="span" sx={{ mx: 1 }}> 
                -
              </Box>
              <Typography component="span" variant="body1">
                {new Date(record.submittedAt).toLocaleString()}
              </Typography>
          </ListItem>
          ))}
        </List>
      </Container>
      <Footer overallTotal={mantraData.overallTotal} />
    </>
  );
}

export default MantraCountPage;
