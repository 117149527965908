import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Container, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const HeaderContainer = styled('header')(({ theme }) => ({
  paddingTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
  textAlign: 'center',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  ...theme.typography.h6,
}));

const LanguageSwitcher = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(0),
}));

const FlagButton = styled('button')({
  fontSize: '24px', // Increase font size for bigger flags
  border: 'none',
  background: 'none',
  cursor: 'pointer',
});

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  cursor: 'pointer',
}));

export const Header = ({ children }) => {
    const { i18n } = useTranslation();

    useEffect(() => {
        document.documentElement.lang = i18n.language;
    }, [i18n.language]);

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('i18nextLng', language);
    };

  return (
    <HeaderContainer>
      <LanguageSwitcher>
        <FlagButton onClick={() => changeLanguage('en')}>🇬🇧</FlagButton>
        <FlagButton onClick={() => changeLanguage('cs')}>🇨🇿</FlagButton>
      </LanguageSwitcher>
      <Container maxWidth="sm">
        <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
          <Title variant="h4" component="h1">
            {children}
          </Title>
        </Link>
      </Container>
    </HeaderContainer>
  );
};
