import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "title": "Ngondro counter for Tenovic Gompa",
      "titlePractice_1": "Prostrations",
      "titlePractice_2": "Diamond mind",
      "titlePractice_3": "Mandala offerings",
      "titlePractice_4": "Gurujoga",
      "titlePractice_5": "Karmapa Chenno",
      "submit": "Submit",
      "totalCollected": "So far, we've collected:",
      "mantras": "mantras",
      "ngondro": "ngöndro",
      "ngondros": "ngöndros",      
      "undo": "Undo",
      "overallTotalFooter": "We made altogether",
      "recentlyAdded": "Recently inserted numbers",
      "insertMantraCount": "Insert mantras",
      "footerText": "In case of issues",
      "footerTextemail": "email us",
      "numberOfProstrations": "prostrations",
      "numberOfOfferings": "mandala offerings",
      "genericErrorMessage": "An error has occured.",
      "emptyFieldError": "The field must be filled.",
      "invalidValueError": "The value must be an integer.",
      "negativeValueError": "The value must be greater than 0.",
      "nonZeroPositiveIntegerError": "Invalid input: Count must be a non-zero, non-negative whole number."
      // Add other key-value pairs for English translations
    }
  },
  cs: {
    translation: {
      "title": "Počítadlo Ngondra pro Těnovickou Gompu",
      "titlePractice_1": "Poklony",
      "titlePractice_2": "Diamantová mysl",
      "titlePractice_3": "Darování mandaly",
      "titlePractice_4": "Gurujóga",
      "titlePractice_5": "Karmapa Čeno",
      "submit": "Odešli",
      "totalCollected": "Dosud jsme shromáždili:",
      "mantras": "manter",
      "ngondro": "ngönder",
      "ngondro_1": "ngöndro",
      "ngondro_2": "ngöndra",
      "undo": "Zpět",
      "overallTotalFooter": "Všichni společne jsme udělali",
      "recentlyAdded": "Nedávno vložené počty",
      "insertMantraCount": "Vložte počet manter",
      "footerText": "V případě problémů kontaktuje",
      "footerTextemail": "emailem",
      "numberOfProstrations": "poklon",
      "numberOfOfferings": "darování mandaly",
      "genericErrorMessage": "Nastala chyba.",
      "emptyFieldError": "Musí být vyplněn počet manter.",
      "invalidValueError": "Mantry musí mít celočíselné hodnoty.",
      "negativeValueError": "Hodnota musí být větší než 0.",
      "nonZeroPositiveIntegerError": "Neplatný vstup: Počet manter musí být celé, nezáporné a nenulové číslo."
      // Add other key-value pairs for Czech translations
    }
  }
};


// Attempt to retrieve the saved language from local storage
const savedLanguage = localStorage.getItem('i18nextLng');

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: savedLanguage,
    fallbackLng: "cs",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
