import React from 'react';
import './i18n';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Container from '@mui/material/Container';
import HomePage from './pages/HomePage'; 
import PracticePage from './pages/PracticePage';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const practices = [
  { path: '/practice/prostrations', theme: '#f5d363', titleKey: 'titlePractice_1', titleDefault: 'Poklony' },
  { path: '/practice/diamond-mind', theme: '#f1b04d', titleKey: 'titlePractice_2', titleDefault: 'Diamantová mysl' },
  { path: '/practice/mandala-offerings', theme: '#ec8e35', titleKey: 'titlePractice_3', titleDefault: 'Darování mandaly' },
  { path: '/practice/guru-yoga', theme: '#d2562d', titleKey: 'titlePractice_4', titleDefault: 'Gurujóga' },
  { path: '/practice/karmapa-chenno', theme: '#42a5f5', titleKey: 'titlePractice_5', titleDefault: 'Karmapa Čeno' },
];

function App() {
  return (
    <BrowserRouter>
      <Container maxWidth="md">
          <Routes>
            {/* Landing page route */}
            <Route path="/" element={<HomePage />} />
            {practices.map((practice, index) => (
              <Route
                key={index}
                path={practice.path}
                element={
                  <ThemeProvider theme={createTheme({ palette: { primary: { main: practice.theme } } })}>
                    <PracticePage titleKey={practice.titleKey} titleDefault={practice.titleDefault} />
                  </ThemeProvider>
                }
              />
            ))}           
          </Routes>
      </Container>
    </BrowserRouter>
  );
}

export default App;
