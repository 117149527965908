import React, { useEffect, useState } from 'react';
import { Box, ButtonBase, Typography, Paper, styled } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';
import { Header } from '../components/Header';
import * as service from '../services/service';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// Items with image paths, links, and specific grid configurations
const items = [
    { id: 1, image: `${process.env.PUBLIC_URL}/practices/prostrations.jpeg`, to: '/practice/prostrations', practiceType: 'prostrations', xs: 6, sm: 6, md: 3 },
    { id: 2, image: `${process.env.PUBLIC_URL}/practices/diamond_mind.jpeg`, to: '/practice/diamond-mind', practiceType: 'diamond-mind', xs: 6, sm: 6, md: 3 },
    { id: 3, image: `${process.env.PUBLIC_URL}/practices/mandala_offerings.jpeg`, to: '/practice/mandala-offerings', practiceType: 'mandala-offerings', xs: 6, sm: 6, md: 3 },
    { id: 4, image: `${process.env.PUBLIC_URL}/practices/guru_joga.jpeg`, to: '/practice/guru-yoga', practiceType: 'guru-yoga', xs: 6, sm: 6, md: 3 },
    // For the 5th item, assuming it should be centered and take the full width on smaller screens
    { id: 5, image: `${process.env.PUBLIC_URL}/practices/16th_karmapa.jpeg`, to: '/practice/karmapa-chenno', practiceType: 'karmapa-chenno', xs: 6, sm: 6, sx: { margin: '0 auto' } },
  ];
  

  function HomePage() {
    const [practiceTotals, setPracticeTotals] = useState({});
    // Using Intl.NumberFormat to format numbers with spaces
    const formatNumber = (number) => {
      return new Intl.NumberFormat('sk-SK').format(number);
    };
    
    useEffect(() => {
      const fetchTotals = async () => {
        try {
          const response = await service.fetchTotalMantras();
          setPracticeTotals(response.totalsPerPractice);
        } catch (error) {
          console.error("Error fetching totals:", error);
        }
      };

      fetchTotals();
    }, []);

  
return (
    <>
      <Header>
        <Box sx={{ textAlign: 'center', fontStyle: 'italic' }}>
          <Typography variant="h4" component="div" gutterBottom>
            Make mantras and wishes for the new Tenovice gompa
          </Typography>
          <Typography variant="subtitle1" component="figcaption">
            — Lama Ole Nydahl
          </Typography>
        </Box>
      </Header>
    <Grid container spacing={3} sx={{ flexGrow: 1, padding: 2, maxWidth: 'lg' }}>
        {items.map((item) => (
        <Grid item="true" xs={item.xs} sm={item.sm} sx={item.sx ? item.sx : {}} key={item.id} display="flex" justifyContent="center" alignItems="center">
            <ButtonBase component={Link} to={item.to} rel="noopener noreferrer"> 
            <Item>
                <img src={item.image} alt={`Item ${item.id}`} style={{ width: '100%', height: 'auto' }} />
            <Typography variant="subtitle1">
              Total: {formatNumber(practiceTotals[item.practiceType] || 0)}
            </Typography>
            </Item>
            </ButtonBase>
        </Grid>
        ))}
    </Grid>
    </>
);
}

export default HomePage;

